var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"form-stepper w-full min-w-0 min-h-[84px] grid grid-cols-3 justify-between gap-3"},[_c('li',{class:{
      'flex flex-col': true,
      'form-stepper--active': _vm.currentFormStep === 1
    }},[_vm._m(0),_c('p',{staticClass:"form-stepper__description ml-10 font-lato text-gray-800 text-sm leading-6"},[_vm._v(" Informasi dasar untuk landing page ")])]),_c('li',{class:{
      'flex flex-col': true,
      'form-stepper--active': _vm.currentFormStep === 2
    }},[_vm._m(1),_c('p',{staticClass:"form-stepper__description ml-10 font-lato text-gray-800 text-sm leading-6"},[_vm._v(" Informasi tentang aplikasi ")])]),_c('li',{class:{
      'flex flex-col': true,
      'form-stepper--active': _vm.currentFormStep === 3
    }},[_vm._m(2),_c('p',{staticClass:"form-stepper__description ml-10 font-lato text-gray-800 text-sm leading-6"},[_vm._v(" Informasi pendukung terkait layanan ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"form-stepper__step w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center flex-shrink-0 text-gray-600"},[_vm._v(" 1 ")]),_c('h2',{staticClass:"form-stepper__title mx-2 font-roboto text-base leading-6 text-gray-600"},[_vm._v(" Pelayanan ")]),_c('div',{staticClass:"w-full h-[2px] bg-gray-300"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"form-stepper__step w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center flex-shrink-0 text-gray-600"},[_vm._v(" 2 ")]),_c('h2',{staticClass:"form-stepper__title mx-2 font-roboto text-base leading-6 text-gray-600"},[_vm._v(" Aplikasi ")]),_c('div',{staticClass:"w-full h-[2px] bg-gray-300"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"form-stepper__step w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center flex-shrink-0 text-gray-600"},[_vm._v(" 3 ")]),_c('h2',{staticClass:"form-stepper__title mx-2 font-roboto text-base leading-6 text-gray-600 whitespace-nowrap"},[_vm._v(" Informasi Tambahan ")])])
}]

export { render, staticRenderFns }